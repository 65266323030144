<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <v-text-field
            id="input-pcode"
            v-model="qrKey"
            label="파레트번호"
            placeholder="QR 스캔"
            clearable
            autocomplete="off"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-if="장소위치코드"
        dense
      >
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="장소위치코드"
            label="기존위치코드"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="장소명"
            label="기존위치명"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-if="isShow위치박스"
          cols="7"
        >
          <v-autocomplete
            id="input-location"
            v-model="selectedLocation"
            :items="locationLookup"
            item-text="장소명"
            item-value="장소코드"
            placeholder="변경위치코드"
            label="변경위치코드"
            @input="onEnterLoation"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-if="locationInfo"
            v-model="locationInfo"
            :readonly="true"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <div style="margin-top:14px; background-color:black;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsInputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          height="200"
          :focused-row-enabled="true"
          :focused-row-key.sync="focusedRowKey"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @focused-row-changed="onSelectionChanged"
        >
          <DxColumnFixing :enabled="false" />
          <DxColumn
            :allow-editing="false"
            caption="NO"
            data-field="입고키"
            sort-order="desc"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :min-width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="납품"
            data-field="수량"
            format="#,##0"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="유효일"
            data-field="유효일"
            data-type="date"
            format="yy/MM/dd"
            :min-width="100"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <!-- <v-row
        v-if="receiptInfo.품명"
        dense
      >
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="receiptInfo.품명"
            label="품명"
            :readonly="true"
          />
        </v-col>
      </v-row> -->
      <div style="margin-top:14px;">
        <v-row dense>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="selectedRow.발주번호"
              label="발주번호"
              :readonly="true"
              filled
              autocomplete="off"
            />
          </v-col>
          <v-col
            cols="8"
          >
            <v-text-field
              v-model="selectedRow.거래처명"
              label="거래처명"
              :readonly="true"
              filled
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <div>
          <loading
            :active.sync="isLoading"
            color="rgb(2, 110, 156)"
            loader="bars"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
import HttpService from '../../share/service/HttpService'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import DxDataGrid, { DxColumnFixing, DxScrolling, DxColumn, DxPaging, DxSorting } from 'devextreme-vue/data-grid'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    QrCodeScanComponent,
    DxDataGrid,
    DxColumnFixing,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting
  },
  data: function () {
    return {
      isShow위치박스: false,
      isLoading: false,
      userInfo: null,
      qrKey: ' ',
      qrLocation: null,
      locationInfo: null,
      pCodeScan: 0,
      selectedRow: {},
      goodsInputListInfo: null,
      focusedRowKey: null,
      // qrcode scan
      showQrScaner: 'none',
      selectedLocation: null,
      locationLookup: [],
      장소명: null,
      장소위치코드: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    HttpService.reqPost(null, '/isLogin')
    this.refreshCompany()
  },
  mounted () {
    this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.qrKey = null
      this.qrLocation = null
      this.pCodeScan = 0
      this.goodsInputListInfo = null
      this.focusedRowKey = null
      this.isShow위치박스 = false
      this.selectedLocation = null
      this.selectedRow = {}
      this.장소명 = null
      this.장소위치코드 = null
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
      }, 100)
    },
    onEnterLoation (e) {
      this.qrLocation = e
      if (this.qrLocation === null) return
      this.showQrScaner = 'none'
      this.isLoading = true
      this.updateLocation(this.qrLocation, this.qrKey, ConstDef.자재입고)
      /*
      this.$_sp.runNoEncodeFindSqlProc('장소위치정보', { 장소위치코드: this.qrLocation })
        .then((data) => {
          const locationInfo = this.$_sp.MakeModel(data)
          if (locationInfo.length === 0) {
            this.isLoading = false
            this.$snotify.error(`${this.qrLocation} 코드의 위치 정보를 검색 할 수 없습니다.`)
            this.initInput()
          } else {
            this.locationInfo = locationInfo[0].위치명
            this.updateLocation(this.qrPcodeKey, this.qrLocation, this.qrKey, ConstDef.자재입고)
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
        */
    },
    onEnterPcode (e) {
      let split = null
      try {
        split = AppLib.base64DecodeSubstring(this.qrKey)
      } catch {
        this.$snotify.error('제품정보를 확인할 수 없습니다.')
        this.initInput()
        return
      }
      this.qrKey = parseInt(split)
      this.$_sp.runNoEncodeFindProc('spFindAllInputListByQkeyAndType', { 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
          if (this.goodsInputListInfo.length > 0) {
            this.isShow위치박스 = true
            this.장소위치코드 = this.goodsInputListInfo[0].장소위치코드
            this.장소명 = this.goodsInputListInfo[0].장소명
            // const sql1 = `select 장소명 from 장소정보 where 장소코드='${this.receiptInfo.장소위치코드}'`
            // this.$_sp.runNoEncodeFindProc('spRunSql', { sql: sql1 })
            //   .then((data) => {
            //     this.장소명 = this.$_sp.MakeModel(data).[0].장소명
            //   })
            //   .catch(error => {
            //     this.isLoading = false
            //     this.initInput()
            //     if (error.response.status === ConstDef.GENERRAL_ERROR) {
            //       this.$snotify.error(error.response.data.message)
            //     } else {
            //       this.$snotify.error(`에러코드 : ${error.response.status}`)
            //     }
            //   })
            setTimeout(() => {
              this.$Q('#input-location').focus()
            }, 100)
          } else {
            this.$snotify.info('해당 파레트번호로 검색된 품목이 없습니다.')
            this.initInput()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      if (this.pCodeScan === 0) {
        this.qrKey = result
        this.pCodeScan = 1
        this.onEnterPcode(null)
      } else {
        this.qrLocation = result
        this.onEnterLoation(null)
      }
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
        // this.$_sp.runNoEncodeFindProc('spFindAllReceiptBypCodeAndKeyAndInput', { 품번: e.row.data.품번, 입고키: this.qrKey, 입출고구분: ConstDef.자재입고 })
        //   .then((data) => {
        //     const receiptInfo = this.$_sp.MakeModel(data)
        //     if (receiptInfo.length !== 1) {
        //       this.$snotify.error('해당 품목의 입고 정보가 잘못 되었습니다.')
        //       this.initInput()
        //     } else {
        //       this.receiptInfo = receiptInfo[0]
        //       setTimeout(() => {
        //         this.$Q('#input-location').focus()
        //       }, 100)
        //     }
        //   })
        //   .catch(error => {
        //     this.isLoading = false
        //     this.initInput()
        //     if (error.response.status === ConstDef.GENERRAL_ERROR) {
        //       this.$snotify.error(error.response.data.message)
        //     } else {
        //       this.$snotify.error(`에러코드 : ${error.response.status}`)
        //     }
        //   })
      }
    },
    updateLocation (location, key, inOutType) {
      const param = {
        입고키: key,
        장소위치코드: location
      }
      this.$_sp.runUpdateSqlProc('창고수불', ['입고키'], null, [param])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          setTimeout(() => {
            this.initInput()
          }, 10)
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshCompany () {
      const sql = 'select * from 장소정보'
      this.$_sp.runNoEncodeFindProc('spRunSql', { sql: sql })
        .then((data) => {
          this.locationLookup = this.$_sp.MakeModel(data)
          for (let i = 0; i < this.locationLookup.length; i++) {
            this.locationLookup[i].장소명 = this.locationLookup[i].장소코드.concat('(', this.locationLookup[i].장소명, ')')
          }
        })
        .catch(error => {
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    test () {
      alert('good')
    }
  }
}
</script>

<style lang="scss">

</style>
